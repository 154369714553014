import firebase from "firebase/app";
import { notification } from "ant-design-vue";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import user from "../../store/user";
import { remove, update } from "lodash";

const developmentConfig = {
  apiKey: "AIzaSyCIVi589qL9apcp9W4M-rW0MGy7DvPxjzg",
  authDomain: "brivvio-development.firebaseapp.com",
  databaseURL: "https://brivvio-development.firebaseio.com",
  projectId: "brivvio-development",
  storageBucket: "brivvio-development.appspot.com",
  messagingSenderId: "361932392129",
  appId: "1:361932392129:web:eefc101460bb2e28e5f640",
  measurementId: "G-ZJVNV96QGY",
};

const productionConfig = {
  apiKey: "AIzaSyC3icyl7Oo9wMFJhmmsVY4uTaEZbT4XBYo",
  authDomain: "brivvio-production.firebaseapp.com",
  databaseURL: "https://brivvio-production.firebaseio.com",
  projectId: "brivvio-production",
  storageBucket: "brivvio-production.appspot.com",
  messagingSenderId: "1004777303227",
  appId: "1:1004777303227:web:2e5e37136051cbafea4d9e",
  measurementId: "G-8DHD13HHRB",
};

firebase.initializeApp(productionConfig);
// firebase.initializeApp(developmentConfig);

export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export async function login(email, password) {
  return firebaseAuth
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      return true;
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function register(email, password, name) {
  var createTrialAccount = functions.httpsCallable("createTrialAccount");
  return createTrialAccount({ email: email, name: name, password: password })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      notification.warning({
        message: error.code,
        description: error.message,
      });
    });
}

export async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser(auth) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebaseAuth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        const getUserData = async (user) => {
          if (user) {
            const userFields = await firestore
              .collection("users")
              .doc(user.uid)
              .get()
              .then((snapshot) => {
                return snapshot.data();
              });
            var mergedUser = Object.assign(user, {
              id: user.uid,
              name: userFields.profile.displayName
                ? userFields.profile.displayName
                : "Anonymous",
              orgs: [],
              currentOrg: {},
              avatar: user.photoUrl ? user.photoUrl : "",
              outsetaId: userFields.data.outsetaId
                ? userFields.data.outsetaId
                : "",
            });
            var userCurrentOrg = userFields.data.currentOrg;
            var orgs = await getAllOrgs();
            if (orgs) {
              if (orgs.length) {
                if (!userCurrentOrg) {
                  userCurrentOrg = userFields.data.orgs[0];
                }

                const currentOrg = await getOrg(userCurrentOrg, user.uid);
                orgs = orgs.map((org) => {
                  return org.id === userCurrentOrg ? currentOrg : org;
                });
                mergedUser.orgs = orgs;
                mergedUser.currentOrg = currentOrg;
              }
            }
            return mergedUser;
          }
          return user;
        };
        resolve(getUserData(user));
      }, reject);
    });
  }
  return getCurrentUser(firebaseAuth);
}

export async function logout() {
  return firebaseAuth.signOut().then(() => true);
}

export async function getOrgData(orgId, userId) {
  const orgRef = firestore.collection("organisations").doc(orgId);

  const orgFields = await orgRef.get().then((snapshot) => {
    if (snapshot.exists) {
      return snapshot.data();
    } else {
      return null;
    }
  });

  if (orgFields) {
    const presets = await orgRef
      .collection("presets")
      .get()
      .then((snapshot) => {
        var presets = [];
        snapshot.docs.forEach((doc) => {
          var isDefaultPreset = false;
          if (doc.id === orgFields.defaultPreset) {
            isDefaultPreset = true;
          }
          const preset = {
            ...doc.data(),
            id: doc.id,
            isDefaultPreset: isDefaultPreset,
            key: doc.id,
            roundedCorners: {
              editable: doc.roundedCorners.editable,
              value: doc.roundedCorners.value * 100,
            },
            textureStrength: {
              editable: doc.textureStrength.editable,
              value: doc.textureStrength.value * 100,
            },
            backgroundColor: {
              editable: doc.backgroundColor.editable,
              value: '#' + doc.backgroundColor.value,
            },
            textColor: {
              editable: doc.textColor.editable,
              value: '#' + doc.textColor.value,
            },
          };
          presets.push(preset);
        });
        return presets;
      });

    const orgUser = orgFields.users.find((user) => user.id === userId);
    var orgUsers = [];
    var licenses = 0;
    if (orgUser && orgUser.role < 2) {
      orgUsers = orgFields.users;
      licenses = orgFields.licenses;
    } else {
      orgUsers = [orgUser];
    }

    const users = orgFields.users.map((user) => {
      return {
        key: user.id,
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
        status: user.status,
      };
    });
    var customFonts = [];
    if (orgFields.customFonts) {
      customFonts = orgFields.customFonts;
    }
    const org = {
      id: orgId,
      name: orgFields.displayName,
      expiryDate: orgFields.expiryDate,
      defaultPreset: orgFields.defaultPreset,
      active: orgFields.active,
      users: users,
      role: orgUser.role,
      licenses: licenses,
      presets: presets,
      currentPlan: orgFields.currentPlan,
      accountStage: orgFields.accountStage,
      numberOfPresets: orgFields.numberOfPresets,
      customFonts: customFonts,
    };
    return org;
  } else {
    return null;
  }
}

export async function addPreset(newPreset, orgId) {
  var preset = newPreset;
  delete preset.id;
  delete preset.key;
  delete preset.isDefaultPreset;
  preset.roundedCorners.value = preset.roundedCorners.value / 100;
  preset.textureStrength.value = preset.textureStrength.value / 100;
  preset.captionsCornerRadius.value = preset.captionsCornerRadius / 100;
  preset.backgroundColor.value = preset.backgroundColor.value.replace('#', '');
  preset.captionsBackgroundColor.value = preset.captionsBackgroundColor.value.replace('#', '');
  preset.captionsTextColor.value = preset.captionsTextColor.value.replace('#', '');
  preset.textColor.value = preset.textColor.value.replace('#', '');
  const ref = firestore
    .collection("organisations")
    .doc(orgId)
    .collection("presets")
    .doc();
  return ref.set(preset).then(() => {
    return { id: ref.id };
  });
}

export async function updatePreset(updatedPreset, orgId, presetId) {
  delete updatedPreset.key;
  delete updatedPreset.isDefaultPreset;
  delete updatedPreset.id;

  var correctedPreset = updatedPreset;
  correctedPreset.roundedCorners.value =
    updatedPreset.roundedCorners.value / 100;
  correctedPreset.textureStrength.value =
    updatedPreset.textureStrength.value / 100;
  correctedPreset.captionsCornerRadius.value =
    updatedPreset.captionsCornerRadius.value / 100
  correctedPreset.backgroundColor.value = updatedPreset.backgroundColor.value.replace('#', '');
  correctedPreset.textColor.value = updatedPreset.textColor.value.replace('#', '');
  correctedPreset.captionsBackgroundColor.value = updatedPreset.captionsBackgroundColor.value.replace('#', '');
  correctedPreset.captionsTextColor.value = updatedPreset.captionsTextColor.value.replace('#', '');

  return firestore
    .collection("organisations")
    .doc(orgId)
    .collection("presets")
    .doc(presetId)
    .set(correctedPreset, { merge: true })
    .then((response) => {
      return true;
    });
}

export async function setDefaultPreset(orgId, presetId) {
  return firestore
    .collection("organisations")
    .doc(orgId)
    .set({ defaultPreset: presetId }, { merge: true })
    .then(() => {
      return true;
    });
}

export async function deletePreset(orgId, presetId) {
  return firestore
    .collection("organisations")
    .doc(orgId)
    .collection("presets")
    .doc(presetId)
    .delete()
    .then(() => {
      return true;
    });
}

export async function uploadImage(imageData, name, orgId, isLogo, meta) {
  const date = new Date().toUTCString();
  const path =
    "organisations/" +
    orgId +
    "/" +
    (isLogo ? "logos" : "textures") +
    "/" +
    name +
    " " +
    date;
  const storageRef = await storage.ref(path);
  return storageRef.put(imageData, meta).then((snap) => {
    return snap.ref.getDownloadURL();
  });
}

export async function uploadFont(fontData, name, orgId, meta) {
  const path = 'organisations/' + orgId + '/Fonts/' + name;
  const storageRef = await storage.ref(path);
  const updateFont = functions.httpsCallable('uploadFont');
  return storageRef.put(fontData, meta).then((snap) => {
    updateFont({ fontName: name, orgId: orgId });
    return snap.ref.getDownloadURL();
  });
}

export async function updateOrg(orgId, updatedData) {
  return firestore
    .collection("organisations")
    .doc(orgId)
    .set(updatedData, { merge: true });
}

export async function removeMember(orgId, userId) {
  try {
    var removeUser = functions.httpsCallable("removeMember");
    const response = await removeUser({ orgId: orgId, userId: userId });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function addMember(orgId, email, name) {
  try {
    var addUser = functions.httpsCallable("addNewMember");
    const response = await addUser({ orgId: orgId, email: email, name: name });
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function resetPassword(email) {
  return firebaseAuth.sendPasswordResetEmail(email);
}

export async function saveProfile(id, profile) {
  return firestore
    .collection("users")
    .doc(id)
    .set({ profile: profile }, { merge: true });
}

export async function updateOrgName(orgId, name) {
  return firestore
    .collection("organisations")
    .doc(orgId)
    .set({ displayName: name }, { merge: true });
}

export async function getAccessToken(email) {
  try {
    var getToken = functions.httpsCallable("getAccessToken");
    const response = await getToken({ email: email });
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function getOrg(orgId, userId) {
  const orgRef = firestore.collection("organisations").doc(orgId);
  const orgFields = await orgRef.get().then((snapshot) => {
    if (snapshot.exists) {
      return snapshot.data();
    } else {
      return null;
    }
  });

  if (orgFields) {
    const presets = await orgRef
      .collection("presets")
      .get()
      .then((snapshot) => {
        var presets = [];
        snapshot.docs.forEach((doc) => {
          var isDefaultPreset = false;
          if (doc.id === orgFields.defaultPreset) {
            isDefaultPreset = true;
          }
          const presetData = doc.data();
          const captionsBackgroundColor = (presetData.captionsBackgroundColor !== undefined ? presetData.captionsBackgroundColor : { editable: true, value: '000000' });
          const captionsTextColor = (presetData.captionsTextColor !== undefined ? presetData.captionsTextColor : { editable: true, value: 'ffffff' });
          const floatingCaptions = (presetData.floatingCaptions !== undefined ? presetData.floatingCaptions : { editable: true, value: false });
          const captionsCornerRadius = (presetData.captionsCornerRadius !== undefined ? presetData.captionsCornerRadius : { editable: true, value: 1 });
          const preset = {
            ...doc.data(),
            id: doc.id,
            isDefaultPreset: isDefaultPreset,
            key: doc.id,
            roundedCorners: {
              editable: presetData.roundedCorners.editable,
              value: presetData.roundedCorners.value * 100,
            },
            textureStrength: {
              editable: presetData.textureStrength.editable,
              value: presetData.textureStrength.value * 100,
            },
            backgroundColor: {
              editable: presetData.backgroundColor.editable,
              value: '#' + presetData.backgroundColor.value,
            },
            textColor: {
              editable: presetData.textColor.editable,
              value: '#' + presetData.textColor.value,
            },
            floatingCaptions: floatingCaptions,
            captionsBackgroundColor: {
              editable: captionsBackgroundColor.editable,
              value: '#' + captionsBackgroundColor.value,
            },
            captionsTextColor: {
              editable: captionsTextColor.editable,
              value: '#' + captionsTextColor.value,
            },
            captionsCornerRadius: captionsCornerRadius,
          };
          presets.push(preset);
        });
        return presets;
      });

    const orgUser = orgFields.users.find((user) => user.id === userId);
    var orgUsers = [];
    var licenses = 0;
    if (orgUser && orgUser.role < 2) {
      orgUsers = orgFields.users;
      licenses = orgFields.licenses;
    } else {
      orgUsers = [orgUser];
    }

    const users = orgFields.users.map((user) => {
      return {
        key: user.id,
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
        status: user.status,
      };
    });
    var role = 2;
    if (orgUser.role) {
      role = orgUser.role;
    }

    var customFonts = [];
    if (orgFields.customFonts) {
      customFonts = orgFields.customFonts
    }
    const org = {
      id: orgId,
      name: orgFields.displayName,
      expiryDate: orgFields.expiryDate,
      defaultPreset: orgFields.defaultPreset,
      active: orgFields.active,
      users: users,
      role: orgUser.role,
      licenses: licenses,
      presets: presets,
      currentPlan: orgFields.currentPlan,
      accountStage: orgFields.accountStage,
      numberOfPresets: orgFields.numberOfPresets,
      customFonts: customFonts,
    };
    return org;
  } else {
    return null;
  }
}

export async function getAllOrgs() {
  try {
    var getAllOrgs = functions.httpsCallable("getAllOrgsFromDashboard");
    const response = await getAllOrgs();
    if (response) {
      return response.data;
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function changeCurrentOrg(userId, orgId) {
  return firestore
    .collection("users")
    .doc(userId)
    .set({ data: { currentOrg: orgId } }, { merge: true });
}

export async function logOutsetaCustomActivity(title, description, entityUid) {
  var logOutsetaEvent = functions.httpsCallable("outsetaAnalytics");
  await logOutsetaEvent({
    title: title,
    description: description,
    activityData: "",
    entityType: 1,
    entityUid: entityUid,
  });
}
