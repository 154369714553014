<template>
  <a-dropdown :trigger="['click']" placement="bottomLeft">
    <div :class="$style.dropdown">
      <a-avatar shape="square" size="large" :class="$style.avatar">
        <template #icon><UserOutlined /></template>
      </a-avatar>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <div>
            <strong
              >{{ $t("topBar.profileMenu.hello") }},
              {{ user.name || "Anonymous" }}</strong
            >
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <div>
            <strong class="mr-1">{{ $t("topBar.profileMenu.email") }}:</strong>
            {{ user.email || "—" }}
          </div>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <a href="javascript: void(0);" @click="goToAccount">
            <i class="fe fe-user mr-2" />
            {{ $t("topBar.profileMenu.editProfile") }}
          </a>
        </a-menu-item>
        <a-menu-divider />
        <a-menu-item>
          <a href="javascript: void(0);" @click="logout">
            <i class="fe fe-log-out mr-2" />
            {{ $t("topBar.profileMenu.logout") }}
          </a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import { UserOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {
    UserOutlined,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const router = useRouter();

    const logout = () => {
      store.dispatch("user/LOGOUT");
    };

    const goToAccount = () => {
      router.push({ path: "/profile" });
    };

    return {
      user,
      logout,
      goToAccount,
    };
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
