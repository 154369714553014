<template>
  <div
    :class="{
      [$style.footerDark]: true,
      [$style.footerFullWidth]: !settings.isContentMaxWidth,
    }"
  >
    <div class="pt-5 pb-4" :class="$style.outer">
      <div :class="$style.container">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <h5 class="font-weight-bold mb-4">Quick Links</h5>
            <div class="row">
              <div class="col-sm-6">
                <div class="d-flex flex-column">
                  <a class="mb-1 vb__utils__link" @click="goToKB">
                    Knowledge Base
                  </a>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="d-flex flex-column mb-4">
                  <div id="help-request"></div>
                  <a
                    class="mb-1 vb__utils__link"
                    href="https://brivvio.outseta.com/support/kb"
                    >Submit help request
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.bottom" class="py-4">
      <div :class="$style.container">
        <div class="d-sm-flex align-items-sm-center">
          <div class="clearfix mr-sm-auto" :class="$style.logo">
            <div :class="$style.logo__letter">
              <logo-full />
            </div>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            <a
              class="mb-1 mb-sm-0 px-sm-3"
              href="https://www.brivvio.com/terms-and-conditions"
              target="_blank"
            >
              Terms &#38; Conditions
            </a>
            <a
              class="mb-1 mb-sm-0 px-sm-3"
              href="https://www.brivvio.com/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <div class="d-flex flex-column flex-sm-row">
            Copyright © {{ new Date().getFullYear() }} Brivvio Pty Ltd
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import LogoFull from "@/components/Logos/Full";
import postscribe from "postscribe";

export default {
  components: { LogoFull },
  setup() {
    const store = useStore();
    const settings = computed(() => store.getters.settings);
    return {
      settings,
    };
  },
  mounted() {
    var dataScript = document.createElement("script");
    dataScript.innerHTML = `var o_options = {
      domain: "brivvio.outseta.com",
      load: "support",
    };`;
    document.body.appendChild(dataScript);
    var script = document.createElement("script");
    script.setAttribute("src", "https://cdn.outseta.com/outseta.min.js");
    script.setAttribute("data-options", "o_options");
    document.body.appendChild(script);
  },
  methods: {
    goToKB() {
      window
        .open("https://brivvio.outseta.com/support/kb/categories", "_blank")
        .focus();
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
