<template>
  <a-layout class="vb__layout">
    <a-layout-content>
      <!-- <vb-sidebar /> -->
      <!-- <vb-support-chat /> -->
      <div
        :class="{
          [$style.container]: true,
          vb__layout__squaredBorders: settings.isSquaredBorders,
          vb__layout__cardsShadow: settings.isCardShadow,
          vb__layout__borderless: settings.isBorderless,
          [$style.white]: settings.authPagesColor === 'white',
          [$style.gray]: settings.authPagesColor === 'gray',
        }"
        :style="{
          backgroundImage:
            settings.authPagesColor === 'image'
              ? `url(resources/images/content/photos/8.jpeg)`
              : 'none',
        }"
      >
        <div
          :class="{
            [$style.topbar]: true,
            [$style.topbarGray]: settings.isGrayTopbar,
          }"
        >
          <div :class="$style.logoContainer">
            <div :class="$style.logo">
              <logo-full />
            </div>
          </div>
        </div>
        <div class="mb-5">
          <router-view v-slot="{ Component }">
            <transition :name="settings.routerAnimation" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
        <div class="mt-auto pb-5 pt-5">
          <ul
            class="list-unstyled d-flex mb-0 flex-wrap justify-content-center"
            :class="[$style.footerNav]"
          >
            <li>
              <a href="https://brivvio.outseta.com/support/kb">Support</a>
            </li>
            <li>
              <a href="https://www.brivvio.com/terms-and-conditions"
                >Terms of Use</a
              >
            </li>
            <li>
              <a href="https://www.brivvio.com/privacy-policy"
                >Privacy Policy</a
              >
            </li>
          </ul>
          <div class="text-center">
            Copyright © {{ new Date().getFullYear() }}
            <a
              href="https://brivvio.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brivvio Pty Ltd
            </a>
          </div>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from "vuex";
import VbSidebar from "@/@vb/components/Sidebar";
import VbSupportChat from "@/@vb/components/SupportChat";
import LogoFull from "@/components/Logos/Full";

export default {
  name: "AuthLayout",
  components: { VbSidebar, VbSupportChat, LogoFull },
  computed: mapState(["settings"]),
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
