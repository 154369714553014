<template>
  <div>
    <svg
      version="1.1"
      id="Layer_2"
      xmlns:x="&ns_extend;"
      xmlns:i="&ns_ai;"
      xmlns:graph="&ns_graphs;"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 600 600"
      style="enable-background: new 0 0 600 600"
      xml:space="preserve"
    >
      <switch>
        <foreignObject
          requiredExtensions="&ns_ai;"
          x="0"
          y="0"
          width="1"
          height="1"
        >
        </foreignObject>
        <g i:extraneous="self">
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="295.7364"
              y1="324.5673"
              x2="441.2946"
              y2="298.9015"
            >
              <stop offset="0" style="stop-color: #644fa1" />
              <stop offset="0.9432" style="stop-color: #f26739" />
            </linearGradient>
            <path
              class="st7"
              d="M391.3,391.1l114.3-66c13.3-7.7,13.3-26.9,0-34.6l-114.3-66V391.1z"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="66.0883"
              y1="317.69"
              x2="248.1953"
              y2="285.5797"
            >
              <stop offset="6.494407e-02" style="stop-color: #644fa1" />
              <stop offset="0.928" style="stop-color: #f15b22" />
            </linearGradient>
            <path
              class="st8"
              d="M220,125.5L132.6,75c-13.3-7.7-30,1.9-30,17.3v430.7c0,15.4,16.7,25,30,17.3L220,490V125.5z"
            />
            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="162.8287"
              y1="322.5519"
              x2="358.3118"
              y2="288.0829"
            >
              <stop offset="0.1513" style="stop-color: #644fa1" />
              <stop offset="0.9286" style="stop-color: #f26739" />
            </linearGradient>
            <polygon
              class="st9"
              points="364.3,208.8 247,141.1 247,474.4 364.3,406.6 			"
            />
          </g>
        </g>
      </switch>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #644fa0;
}
.st1 {
  fill: #80528f;
}
.st2 {
  fill: #935683;
}
.st3 {
  fill: #a75a76;
}
.st4 {
  fill: #c65e61;
}
.st5 {
  fill: #db634f;
}
.st6 {
  fill: #f16739;
}
.st7 {
  fill: url(#SVGID_1_);
}
.st8 {
  fill: url(#SVGID_2_);
}
.st9 {
  fill: url(#SVGID_3_);
}
</style>