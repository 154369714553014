export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  {
    title: "Organisation",
    key: "__dashboard",
    url: "/",
    icon: "fe fe-home",
  },
  {
    title: "Presets",
    key: "nmfir",
    url: "/presets",
    icon: "fe fe-sliders",
  },
  {
    title: "Team",
    key: "2wwzdc",
    url: "/team",
    icon: "fe fe-users",
  },
  {
    title: "Billing",
    key: "9ewpgd",
    url: "/billing",
    icon: "fe fe-clipboard",
  },
  // {
  //   title: "Account",
  //   key: "p1js9",
  //   url: "/account",
  //   icon: "fe fe-user",
  // },

  // VB:REPLACE-END:MENU-CONFIG
];
