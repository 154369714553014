<template>
  <div class="card-header-flex">
    <div
      class="d-flex flex-column justify-content-center mr-auto align-items-left"
    >
      <a-dropdown :trigger="['click']" placement="bottomLeft">
        <div :class="$style.dropdown">
          <h5>
            <strong>{{ currentOrg.name }}</strong>
          </h5>
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="org in orgs" :key="org.id">
              <a @click="switchOrg(org.id)">{{ org.name }}</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["switchOrg"],
  props: ["orgs", "currentOrg"],
  setup(props) {
    const orgs = props.orgs;
    const currentOrg = props.currentOrg;

    return { orgs, currentOrg };
  },
  methods: {
    switchOrg(key) {
      this.$emit("switchOrg", key);
    },
  },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
