<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns:x="&ns_extend;"
      xmlns:i="&ns_ai;"
      xmlns:graph="&ns_graphs;"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 2400 600"
      style="enable-background: new 0 0 2400 600"
      xml:space="preserve"
    >
      <switch>
        <foreignObject
          requiredExtensions="&ns_ai;"
          x="0"
          y="0"
          width="1"
          height="1"
        >
        </foreignObject>
        <g i:extraneous="self">
          <g>
            <path
              class="st0"
              d="M753,545.4c-12.9,0-21.7-2.5-26.9-7.7c-5.2-5.2-7.7-14.2-7.7-27.5v-402c0-12.8,2.3-21.5,6.9-26.4
				c4.7-5,13.3-7.5,26.3-7.5h71.2c53.4,0,93,7.6,117.9,22.4c35.3,21.5,53.1,56.2,53.1,103.1l0,0.2c-0.9,21.8-7.1,42.7-18.5,62.2
				c-9.4,16.2-23.7,29-42.6,38.1c16.7,5.5,31,13.8,42.7,24.8c14.8,13.9,24.8,28.2,29.9,42.5c5,14.1,7.5,31.1,7.5,50.4
				c0,19.5-4.7,39.5-14,59.4c-9.4,20.2-23.2,35.7-41.2,46.3l-0.1,0.1c-25.7,14.4-62.4,21.7-109.2,21.7H753z M859.1,503.4
				c74.8,0,111.1-29.2,111.1-89.4c0-24.7-9.9-45.6-30.3-63.9c-20.3-18.2-49.5-27.4-86.7-27.4H809c-5.2,0-8.7-0.1-11.1-0.4
				c-3.2-0.3-6.6-2-10.2-5.1c-4-3.3-6-8.8-6-16.2c0-7.9,3.6-13.6,10.1-16.1c5.2-2,12.2-3,21.2-3h41.5c34.8,0,59.9-8.5,74.4-25.3
				c14.7-17,22.2-36.3,22.2-57.4c0-21.8-7.1-40.2-21-54.6l-0.1-0.1c-17-18.2-53.3-27.4-107.8-27.4h-61v386.3H859.1z"
            />
            <path
              class="st1"
              d="M1075.9,545.4c-4.8,0-9-1.7-12.5-4.9c-3.6-3.3-5.5-7.9-5.5-13.2V318.5c0-37.3,10.7-65.9,31.9-85
				c21-19,50.1-28.6,86.3-28.6c11.1,0,17.4,6.6,17.4,18.1v4.6c0,4.8-1.6,9-4.9,12.5c-3.3,3.6-7.7,5.5-12.5,5.5
				c-23.7,0-42.9,6-56.8,17.9c-13.7,11.7-20.7,30.2-20.7,54.9v208.9c0,5.3-1.9,9.9-5.5,13.2c-3.5,3.3-7.8,4.9-12.5,4.9H1075.9z"
            />
            <path
              class="st2"
              d="M1269.6,545.4c-4.8,0-9-1.7-12.5-4.9c-3.6-3.3-5.5-7.9-5.5-13.2V223.5c0-7.6,1.9-12.5,5.9-15
				c3.2-2,7.3-3.1,12.2-3.1h11.2c6.9,0,11.6,1.7,14.4,5.1c2.5,3.1,3.7,7.5,3.7,12.9v303.8c0,5.3-1.9,9.9-5.5,13.2
				c-3.5,3.3-7.8,4.9-12.5,4.9H1269.6z M1274.3,138.1c-17.5,0-28.1-2.3-33.6-7.2c-5.6-5-8.2-15.2-8.2-32v-8.6
				c0-15.9,2.6-25.6,8.3-30.4c5.4-4.6,16.1-6.8,33.5-6.8c17.2,0,28.3,2.4,34.2,7.4c6,5.2,8.9,14.9,8.9,29.7V99c0,16.6-3,27.1-9,32.1
				C1302.5,135.9,1291.6,138.1,1274.3,138.1z M1266.1,104.3c0.4,0.2,2.5,1,8.8,1c6.3,0,8.4-0.7,8.8-1c0.2-0.3,0.8-1.7,0.8-5.4v-6.6
				c0-3.3-0.7-4.7-1.1-5c-0.1-0.1-1.7-1.3-8.9-1.3c-6.1,0-8.1,0.7-8.5,1c-0.2,0.3-0.8,1.7-0.8,5.4V99
				C1265.3,102.7,1265.9,104,1266.1,104.3z"
            />
            <path
              class="st3"
              d="M1484.5,546.1c-13.9,0-23.7-7.3-28.3-21.1l-108.8-295.4l-0.1-0.2c-0.5-2.1-0.8-4.4-0.8-6.8
				c0-3,1.3-6.3,3.8-10c2.9-4.2,7.7-6.4,14.2-6.4h5.9c4.9,0,11.9,1.9,16.8,11.1l0.2,0.3l98.3,269.2l97.6-269l0.1-0.2
				c3.4-7.5,9.3-11.4,17-11.4h5.9c8.1,0,12.2,3.5,14.2,6.4c2.6,3.7,3.8,7,3.8,10c0,2.3-0.3,4.6-0.8,6.8l-0.1,0.3l-108.9,295l0,0.1
				C1508.7,538.7,1498.3,546.1,1484.5,546.1z"
            />
            <path
              class="st4"
              d="M1786.3,546.1c-13.9,0-23.7-7.3-28.3-21.1l-108.8-295.4l-0.1-0.2c-0.5-2.1-0.8-4.4-0.8-6.8
				c0-3,1.3-6.3,3.8-10c2.9-4.2,7.7-6.4,14.2-6.4h5.9c4.9,0,11.9,1.9,16.8,11.1l0.2,0.3l98.3,269.2l97.6-269l0.1-0.2
				c3.4-7.5,9.3-11.4,17-11.4h5.9c8.1,0,12.2,3.5,14.2,6.4c2.6,3.7,3.8,7,3.8,10c0,2.3-0.3,4.6-0.8,6.8l-0.1,0.3l-108.9,295l0,0.1
				C1810.5,538.7,1800.1,546.1,1786.3,546.1z"
            />
            <path
              class="st5"
              d="M2001.2,545.4c-4.8,0-9-1.7-12.5-4.9c-3.6-3.3-5.5-7.9-5.5-13.2V223.5c0-7.6,1.9-12.5,5.9-15
				c3.2-2,7.3-3.1,12.2-3.1h11.2c6.9,0,11.6,1.7,14.4,5.1c2.5,3.1,3.7,7.5,3.7,12.9v303.8c0,5.3-1.9,9.9-5.5,13.2
				c-3.5,3.3-7.8,4.9-12.5,4.9H2001.2z M2005.8,138.1c-17.5,0-28.1-2.3-33.6-7.2c-5.6-5-8.2-15.2-8.2-32v-8.6
				c0-15.9,2.6-25.6,8.3-30.4c5.4-4.6,16.1-6.8,33.5-6.8c17.2,0,28.3,2.4,34.2,7.4c6,5.2,8.9,14.9,8.9,29.7V99c0,16.6-3,27.1-9,32.1
				C2034,135.9,2023.2,138.1,2005.8,138.1z M1997.6,104.3c0.4,0.2,2.5,1,8.8,1c6.3,0,8.4-0.7,8.8-1c0.2-0.3,0.8-1.7,0.8-5.4v-6.6
				c0-3.3-0.7-4.7-1.1-5c-0.1-0.1-1.7-1.3-8.9-1.3c-6.1,0-8.1,0.7-8.5,1c-0.2,0.3-0.8,1.7-0.8,5.4V99
				C1996.9,102.7,1997.4,104,1997.6,104.3z"
            />
            <path
              class="st6"
              d="M2209.8,546.8c-34.7,0-63.5-10.1-85.6-29.9c-22.3-20-33.6-48.1-33.6-83.7V318.5c0-36,11.2-64.3,33.3-84
				c21.9-19.6,50.7-29.6,85.6-29.6c34.9,0,63.5,10.1,85,30c21.6,20,32.5,48.1,32.5,83.7v114.7c0,35.5-10.9,63.7-32.5,83.7
				C2273,536.7,2244.5,546.8,2209.8,546.8z M2209.5,245.6c-22.4,0-41.3,6.4-56.2,18.9c-14.8,12.5-22,30.2-22,53.9v114.7
				c0,23.8,7.2,41.4,22,53.9c14.8,12.6,33.7,18.9,56.2,18.9c22.4,0,41.1-6.4,55.4-18.9c14.2-12.4,21.4-30.5,21.4-54V318.5
				c0-23.8-7-41.4-21.4-54C2250.5,251.9,2231.8,245.6,2209.5,245.6z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="279.7364"
              y1="326.5673"
              x2="425.2946"
              y2="300.9015"
            >
              <stop offset="0" style="stop-color: #644fa0" />
              <stop offset="0.9432" style="stop-color: #f16739" />
            </linearGradient>
            <path
              class="st7"
              d="M375.3,393.1l114.3-66c13.3-7.7,13.3-26.9,0-34.6l-114.3-66V393.1z"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="50.0883"
              y1="319.69"
              x2="232.1953"
              y2="287.5797"
            >
              <stop offset="6.494407e-02" style="stop-color: #644fa0" />
              <stop offset="0.928" style="stop-color: #f05b22" />
            </linearGradient>
            <path
              class="st8"
              d="M204,127.5L116.6,77c-13.3-7.7-30,1.9-30,17.3v430.7c0,15.4,16.7,25,30,17.3L204,492V127.5z"
            />
            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="146.8287"
              y1="324.5519"
              x2="342.3118"
              y2="290.0829"
            >
              <stop offset="0.1513" style="stop-color: #644fa0" />
              <stop offset="0.9286" style="stop-color: #f16739" />
            </linearGradient>
            <polygon
              class="st9"
              points="348.3,210.8 231,143.1 231,476.4 348.3,408.6 			"
            />
          </g>
        </g>
      </switch>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  fill: #644fa0;
}
.st1 {
  fill: #80528f;
}
.st2 {
  fill: #935683;
}
.st3 {
  fill: #a75a76;
}
.st4 {
  fill: #c65e61;
}
.st5 {
  fill: #db634f;
}
.st6 {
  fill: #f16739;
}
.st7 {
  fill: url(#SVGID_1_);
}
.st8 {
  fill: url(#SVGID_2_);
}
.st9 {
  fill: url(#SVGID_3_);
}
</style>
